import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * 檢查Agentcode
 * @param data
 * @returns {*}
 * @constructor
 */

function CheckAgentCode(data) {
  return request({
    url: '/api/platform/check/agentcode',
    method: 'post',
    data
  })
}

function CheckMaintain() {
  return request({
    url: 'api/platform/check/maintain',
    method: 'post'
  })
}

function getSkipSmsOTP() {
  return request({
    url: 'api/skipSmsOTP/get',
    method: 'get'
  })
}

var service = {
  CheckAgentCode: CheckAgentCode,
  CheckMaintain,
  getSkipSmsOTP
}
export default service
