import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * 取得公告列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_BulletinList(data) {
  return request({
    url: '/api/bulletin/list',
    method: 'post',
    data
  })
}

/**
 * 取得廣告列表
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_AdvertisementList(data) {
  return request({
    url: '/api/advertisement/list',
    method: 'post',
    data
  })
}

function Get_SystemList(data) {
  return request({
    url: '/api/systemmail/list',
    method: 'post',
    data
  })
}

function Get_NoreadMail(data) {
  return request({
    url: '/api/systemmail/noread',
    method: 'post',
    data
  })
}

function Read_Mail(data) {
  return request({
    url: '/api/systemmail/read',
    method: 'post',
    data
  })
}

var service = {
  Get_BulletinList: Get_BulletinList,
  Get_AdvertisementList: Get_AdvertisementList,
  Get_SystemList: Get_SystemList,
  Get_NoreadMail,
  Read_Mail
}

export default service
