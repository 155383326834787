import moment from 'moment'
import store from '@/store'
import Precision from '../utils/Decimal'
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '../utils'

function toThousandMark(num) {
  return num.toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

function financial(x, point = 2) {
  if (point === 0) return x
  const rex = /([0-9]+\.[0-9]{2})[0-9]*/
  let s = String(x).replace(rex, '$1')
  let rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + point) {
    s += '0'
  }
  return s
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      )
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000" or 1000000 => 1000K
 * @param {number} num
 */
export function toThousandFilter(num, point = 2) {
  // num = Math.abs(num)
  var changeNum = 0
  if (store.getters.CurrencyID === 5) {
    if (num === 0) {
      changeNum = financial(0)
    } else {
      if (Math.abs(num) >= 1000) {
        const num_divide = Precision.divide(num, 1000)
        changeNum = toThousandMark(financial(num_divide, point)) + 'K'
      } else {
        changeNum = toThousandMark(financial(num, point))
      }
    }
  } else {
    changeNum = toThousandMark(financial(num, point))
  }

  return changeNum
}

/**
 * 超過1000顯示1K, 最多小數點後兩位
 * @param {number} num
 */
export function diamondFilter(num) {
  let changeNum
  if (num === 0) {
    return 0
  } else {
    const isGreaterThan1000 = Math.abs(num) >= 1000
    const num_divide = isGreaterThan1000 ? Precision.divide(num, 1000) : num
    const rex = /([0-9]+\.[0-9]{2})[0-9]*/
    const s = String(num_divide).replace(rex, '$1')
    const point = s.split('.')[1] ? s.split('.')[1].length : 0
    const res = toThousandMark(financial(num_divide, point))
    changeNum = isGreaterThan1000 ? res + 'K' : res
  }

  return changeNum
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatBirthday(value) {
  if (value && value !== '-') {
    return moment(value).format('YYYY-MM-DD')
  } else {
    return value
  }
}

// 轉為千分號
export function currency(price) {
  // return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  // price = Math.abs(price)
  if (price && /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,20})?$/g.test(price)) {
    if (store.getters.CurrencyID === 5) {
      if (price === 0) {
        return financial(0)
      } else {
        if (Math.abs(price) >= 1000) {
          const price_divide = Precision.divide(price, 1000)
          return toThousandMark(financial(price_divide)) + 'K'
        } else {
          return toThousandMark(financial(price))
        }
      }
    } else {
      return toThousandMark(financial(price))
      // return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  } else {
    return financial(0)
  }
  /* return price.toLocaleString('en-US');  寫法二：轉為千分號*/
}

// 添加$字號
export function filterDollarSign(price) {
  return `$ ${price}`
}
