import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * Member Login
 * @param data = { Account, Password}
 * @constructor
 */
function Login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

/**
 * Member Register
 * @param data = { Account, Password}
 * @constructor
 */
function Register(data) {
  return request({
    url: '/api/user/create',
    method: 'post',
    data
  })
}

/**
 * User Check
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function UserCheck(data) {
  return request({
    url: '/api/user/check',
    method: 'post',
    data
  })
}

/**
 * Forget Password
 * @param data = { Account, CellPhoneNumber}
 * @constructor
 */
function Forget(data) {
  return request({
    url: '/api/user/forget',
    method: 'post',
    data
  })
}

/**
 * 取得會員資訊
 * @param data
 * @returns {AxiosPromise}
 */
function getInfo(data) {
  return request({
    url: '/api/user/info',
    method: 'post',
    data
  })
}

/**
 * 會員綁定檢查
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function BindCheck(data) {
  return request({
    url: '/api/user/bind/check',
    method: 'post',
    data
  })
}

/**
 * Change Password >> 忘記密碼用
 * @param data = { Account, Password}
 * @constructor
 */
function ChangePassword(data) {
  return request({
    url: '/api/user/security/change',
    method: 'post',
    data
  })
}

/**
 * Revised Password >> 一般修改密碼用
 * @param data = { Account, Password}
 * @constructor
 */
function RevisedPassword(data) {
  return request({
    url: '/api/user/change',
    method: 'post',
    data
  })
}

/**
 * Resend VerifyCode
 * @param data = { New Password}
 * @constructor
 */
function ResendVerifyCode(data) {
  return request({
    url: '/api/user/security/resend',
    method: 'post',
    data
  })
}

/**
 * 手機綁定
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function BindPhone(data) {
  return request({
    url: '/api/user/bind/phone',
    method: 'post',
    data
  })
}

/**
 * 會員綁定
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function BindMember(data) {
  return request({
    url: '/api/user/bind/info',
    method: 'post',
    data
  })
}

/**
 * 推薦人綁定
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function BindReferrer(data) {
  return request({
    url: '/api/user/bind/referrer',
    method: 'post',
    data
  })
}

/**
 * 取得錢包
 * @param data
 * @returns {AxiosPromise}
 * @constructor
 */
function GetWallet(data) {
  return request({
    url: '/api/user/wallet',
    method: 'post',
    data
  })
}

/**
 * 綁定 facebook
 * @param data
 * @returns {*}
 * @constructor
 */
function BindFacebook(data) {
  return request({
    url: '/api/user/bind/fb',
    method: 'post',
    data
  })
}

/**
 * 取得推薦人分享鏈結
 * @param data
 * @returns {*}
 * @constructor
 */
function SharedReferrer(data) {
  return request({
    url: '/api/user/referrer/link',
    method: 'post',
    data
  })
}

/**
 * 綁定帳號(利用三方登入的帳號才有)
 * @param data
 * @returns {*}
 * @constructor
 */
function BindAccount(data) {
  return request({
    url: '/api/user/bind/account',
    method: 'post',
    data
  })
}

function FBAccountCreate(data) {
  return request({
    url: '/api/user/fb/create',
    method: 'post',
    data
  })
}

function UserVerifyPhone(data) {
  return request({
    url: '/api/user/security/verifyphone',
    method: 'post',
    data
  })
}

function updatePhone(data) {
  return request({
    url: '/api/user/phone/update',
    method: 'post',
    data
  })
}

function getReferrerRecord(data) {
  return request({
    url: '/api/user/referrer/record',
    method: 'post',
    data
  })
}

function getHasPromoCode(data) {
  return request({
    url: '/api/activity/promocode_check',
    method: 'get',
    data
  })
}

function checkRefToken(data) {
  return request({
    url: '/api/user/referrer/token_check',
    method: 'post',
    data
  })
}

const service = {
  Login: Login,
  Register: Register,
  UserCheck: UserCheck,
  Forget: Forget,
  getInfo: getInfo,
  BindCheck: BindCheck,
  ChangePassword: ChangePassword,
  ResendVerifyCode: ResendVerifyCode,
  BindPhone: BindPhone,
  BindMember: BindMember,
  BindReferrer: BindReferrer,
  GetWallet: GetWallet,
  RevisedPassword: RevisedPassword,
  BindFacebook: BindFacebook,
  SharedReferrer: SharedReferrer,
  BindAccount: BindAccount,
  FBAccountCreate,
  UserVerifyPhone,
  updatePhone,
  getReferrerRecord,
  getHasPromoCode,
  checkRefToken
}
export default service
