import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

function GetList(data) {
  return request({
    url: '/api/platform/language/list',
    method: 'post',
    data
  })
}

var service = {
  GetList: GetList
}

export default service
