import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

function Check(data) {
  return request({
    url: '/api/platform/version/check',
    method: 'post',
    data
  })
}

const service = {
  Check: Check
}

export default service
