import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * 確認訂閱(已登入)
 * @param data
 * @returns {*}
 * @constructor
 */
function SubscribeHasLogin(data) {
  return request({
    url: '/api/notify/channel/subscribe/yes',
    method: 'post',
    data
  })
}

/**
 * 確認訂閱(未登入)
 * @param data
 * @returns {*}
 * @constructor
 */
function SubscribeNoLogin(data) {
  return request({
    url: '/api/notify/channel/subscribe/no',
    method: 'post',
    data
  })
}

var service = {
  SubscribeHasLogin: SubscribeHasLogin,
  SubscribeNoLogin: SubscribeNoLogin
}

export default service
