import User from './User'
import Language from './Language'
import Bank from './Bank'
import Version from './Version'
import Financial from './Financial'
import Game from './Game'
import Activity from './Activity'
import System from './System'
import Message from './Message'
import Community from './Community'
import Notification from './Notification'

export default {
  User,
  Language,
  Bank,
  Version,
  Financial,
  Game,
  Activity,
  System,
  Message,
  Community,
  Notification
}
