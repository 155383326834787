import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * 取得銀行列表
 * @param data
 * @returns {AxiosPromise}
 */
function getList(data) {
  return request({
    url: '/api/bank/list',
    method: 'post',
    data
  })
}

/**
 * 取得會員存摺
 * @param data
 * @returns {AxiosPromise}
 */
function GetAccounting(data) {
  return request({
    url: '/api/record/accountinglog',
    method: 'post',
    data
  })
}

/**
 * 取得存款記錄
 * @param data
 * @returns {AxiosPromise}
 */
function GetCashIn(data) {
  return request({
    url: '/api/record/cashin',
    method: 'post',
    data
  })
}

/**
 * 取得提款記錄
 * @param data
 * @returns {AxiosPromise}
 */
function GetCashOut(data) {
  return request({
    url: '/api/record/cashout',
    method: 'post',
    data
  })
}

var service = {
  getList: getList,
  GetAccounting: GetAccounting,
  GetCashIn: GetCashIn,
  GetCashOut: GetCashOut
}

export default service

