import httpRequest from '../../utils/request'

const request = (...arg) => httpRequest.then(res => {
  return res(...arg)
})

/**
 * 產品社群連結
 * @param data
 * @returns {*}
 * @constructor
 */
function Get_CommunityLink(data) {
  return request({
    url: '/api/platform/product/community/list',
    method: 'post',
    data
  })
}

var service = {
  Get_CommunityLink: Get_CommunityLink
}

export default service
